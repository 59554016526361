
import { isMobile } from "../utils/is-mobile";
import scrollLock from "../utils/scroll-lock";
import Plyr from 'plyr';
import "../../plyr/plyr.min.css";

let plyrPlayer;

$(".js-popup-video").on("click", function (e) {
	e.preventDefault();

	const $button = $(this);
	const videoType = $button.data("type");
	const videoPoster = $button.data("poster");
	const videoSrc = $button.attr("href");

	if (videoType == "youtube") {
		$(".plyr .plyr__video-embed--youtube")
			.show()
			.find("iframe")
			.attr("src", videoSrc);

		plyrPlayer = new Plyr(".plyr .plyr__video-embed--youtube", {
			muted: true,
			controls: [
				"play-large",
				"play",
				"progress",
				"current-time",
				"mute",
				"volume",
				"settings",
				"fullscreen",
			],
			youtube:{playsinline: 1 }
		});
	} else {
		$(".plyr .plyr__video-embed--self-hosted")
			.html(`<video playsinline controls data-poster="${videoPoster}">
					<source src="${videoSrc}" type="video/mp4" />
				</video>`);

		plyrPlayer = new Plyr(".plyr .plyr__video-embed--self-hosted video", {
			muted: true,
			controls: [
				"play-large",
				"play",
				"progress",
				"current-time",
				"mute",
				"volume",
				"settings",
				"fullscreen",
			],
		});

		setTimeout(function () {
			$(".plyr .plyr__video-embed--self-hosted").show();
		}, 100);
	}

	$(".popup.popup--video").addClass("is-active");

	scrollLock.enable();

	plyrPlayer.on("ready", (event) => {
		plyrPlayer.play();

		if (!isMobile()) {
			plyrPlayer.increaseVolume(100);
		}
	});
});

$(".popup.popup--video .popup__close, .popup.popup--video .popup__bg").on(
	"click",
	function (e) {
		e.preventDefault();

		$(".popup.popup--video").removeClass("is-active");

		scrollLock.disable();

		setTimeout(function () {
			if ($(".popup--video").find(".plyr--youtube").length) {
				$(".popup--video")
					.find(".plyr--youtube")
					.remove()
					.end()
					.find(".plyr")
					.append(`<div class="plyr__video-embed plyr__video-embed--youtube" style="display: none;">
						<iframe
						src=""
						allowfullscreen
						allowtransparency
						allow="autoplay"
						></iframe>
					</div>
					`);
			}

			$(".plyr .plyr__video-embed").hide();

			plyrPlayer.destroy();
		}, 400);
	}
);
