const triggerGTMCustomEvent = () => {
	$( document ).ready( function() {
		$( document ).on( "nfFormSubmitResponse" , function( e, response ) {
			const event = new CustomEvent( "NinjaSubmission", {
				detail: response
			} );
            
			window.dispatchEvent( event );
		} );
	} );
};

$( function() {
	triggerGTMCustomEvent();
} );
