$( window ).scroll( function() {
	$( ".fade-in" ).each( function() {
		var position = $( this ).offset().top;
		var scroll = $( window ).scrollTop();
		var windowHeight = $( window ).height();

		if ( scroll > position - windowHeight + 100 ) {
			$( this ).addClass( "visible" );
		}
	} );
} );