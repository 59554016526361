import { $doc } from "../utils/globals";
import scrollLock from "../utils/scroll-lock";

$doc.on( "click", ".js-popup-request", function( e ) {
	e.preventDefault();

	if ( $( ".popup.is-active" ).length === 0 ) {
		scrollLock.enable();
	}

	$( ".popup.popup--form" ).addClass( "is-active" );
} );

$doc.on( "click", ".js-popup-portfolio", function( e ) {
	e.preventDefault();

	if ( $( ".popup.is-active" ).length === 0 ) {
		scrollLock.enable();
	}
	$( ".popup.popup--portfolio" ).addClass( "is-active" );
} );

$doc.on(
	"click",
	".popup:not(.popup--video) .popup__close, .popup:not(.popup--video) .popup__bg",
	function( e ) {
		e.preventDefault();

		$( this ).closest( ".popup" ).removeClass( "is-active" );

		if ( $( ".popup.is-active" ).length === 0 ) {
			scrollLock.disable();

			$( "body" ).removeClass( "popup-active" );
		}
	}
);

$doc.on( "click", ".hamburger", function() {
	$( "body" ).hasClass( "is-nav-active" )
		? scrollLock.enable()
		: scrollLock.disable();
} );

$doc.on(
	"click",
	".header__menu--bg",
	function() {
		scrollLock.disable();

		$( "body" ).removeClass( "is-nav-active" );

		$( ".header" ).removeClass( "menu-open" );
	}
);

$( window ).on( "resize", function() {
	var win = $( this );
	if ( win.width() > 1199 ) {
		if ( $( ".header" ).hasClass( "menu-open" ) ) {
			$( "header" ).removeClass( "menu-open" );
			$( "body" ).removeClass( "is-nav-active" );
			scrollLock.disable();
		}
	}
} );
